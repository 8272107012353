import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import NumberInputBox from './NumberInputBox';

import classes from './SliderArea.module.css';

const SliderArea = (props) => {
    const handleDemThirdPartyChange = (newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        if (props.values.dem.stayHome + props.values.dem.voteOpposite + newValue > 100) {
            return;
        }
        let cloneValues = { ...props.values };

        cloneValues.dem.thirdParty = newValue;
        props.onChange(cloneValues);
    }
    
    const handleDemStayHomeChange = (newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        if (props.values.dem.thirdParty + props.values.dem.voteOpposite + newValue > 100) {
            return;
        }
        
        let cloneValues = { ...props.values };

        cloneValues.dem.stayHome = newValue;
        props.onChange(cloneValues);
    }
    const handleDemVoteOppositeChange = (newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        if (props.values.dem.thirdParty + props.values.dem.stayHome + newValue > 100) {
            return;
        }
        let cloneValues = { ...props.values };

        cloneValues.dem.voteOpposite = newValue;
        props.onChange(cloneValues);
    }
    const handleRepThirdPartyChange = (newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        if (props.values.rep.stayHome + props.values.rep.voteOpposite + newValue > 100) {
            return;
        }
        let cloneValues = { ...props.values };

        cloneValues.rep.thirdParty = newValue;
        props.onChange(cloneValues);
    }
    const handleRepStayHomeChange = (newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        if (props.values.rep.thirdParty + props.values.rep.voteOpposite + newValue > 100) {
            return;
        }
        let cloneValues = { ...props.values };

        cloneValues.rep.stayHome = newValue;
        props.onChange(cloneValues);
    }
    const handleRepVoteOppositeChange = (newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        if (props.values.rep.thirdParty + props.values.rep.stayHome + newValue > 100) {
            return;
        }
        let cloneValues = { ...props.values };

        cloneValues.rep.voteOpposite = newValue;
        props.onChange(cloneValues);
    }

    const voteDem = 100 - props.values.dem.thirdParty - props.values.dem.stayHome - props.values.dem.voteOpposite;
    const voteRep = 100 - props.values.rep.thirdParty - props.values.rep.stayHome - props.values.rep.voteOpposite;

    return (
        <Container className={classes.tabArea}>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="dem"
                        id="slider-area"
                        variant='pills'
                        className="mb-12"
                    >
                        <Tab eventKey="dem" title="Democrats" className={classes.tabBlock}>
                            <Container>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <h4>Democrats (Vote D {voteDem}%)</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={4}>   
                                        <NumberInputBox
                                            id="dem-third-party"
                                            label={"Third Party"}
                                            min={0}
                                            max={100 - props.values.dem.stayHome - props.values.dem.voteOpposite}
                                            value={props.values.dem.thirdParty}
                                            onChange={handleDemThirdPartyChange}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <NumberInputBox
                                            id="dem-stay-home"
                                            label={"Stay Home"}
                                            min={0}
                                            max={100 - props.values.dem.thirdParty - props.values.dem.voteOpposite}
                                            value={props.values.dem.stayHome}
                                            onChange={handleDemStayHomeChange}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <NumberInputBox
                                            id="dem-vote-opposite"
                                            label={"Vote Rep"}
                                            min={0}
                                            max={100 - props.values.dem.thirdParty - props.values.dem.stayHome}
                                            value={props.values.dem.voteOpposite}
                                            onChange={handleDemVoteOppositeChange}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="rep" title="Republicans" className={classes.tabBlock}>
                            <Container>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <h4>Republicans (Vote R {voteRep}%)</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={4}>   
                                        <NumberInputBox
                                            id="rep-third-party"
                                            label={"Third Party"}
                                            min={0}
                                            max={100 - props.values.rep.stayHome - props.values.rep.voteOpposite}
                                            value={props.values.rep.thirdParty}
                                            onChange={handleRepThirdPartyChange}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <NumberInputBox
                                            id="rep-stay-home"
                                            label={"Stay Home"}
                                            min={0}
                                            max={100 - props.values.rep.thirdParty - props.values.rep.voteOpposite}
                                            value={props.values.rep.stayHome}
                                            onChange={handleRepStayHomeChange}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <NumberInputBox
                                            id="rep-vote-opposite"
                                            label={"Vote Dem"}
                                            min={0}
                                            max={100 - props.values.rep.thirdParty - props.values.rep.stayHome}
                                            value={props.values.rep.voteOpposite}
                                            onChange={handleRepVoteOppositeChange}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
}

export default SliderArea;