import { Container, Row, Col } from 'react-bootstrap';
import { Initial_Map_State, calculateElectoralVotes } from '../utility/utilityFunctions';

import classes from './ElectoralVotes.module.css';
import ElectoralVoteSection from './ElectoralVoteSection';
 
const ElectoralVotes = (props) => {
    const MapData = Initial_Map_State();
    const originalElectoralVotes = calculateElectoralVotes(MapData, {
        dem: {
            thirdParty: 0,
            stayHome: 0,
            voteOpposite: 0
        },
        rep: {
            thirdParty: 0,
            stayHome: 0,
            voteOpposite: 0
        },
    });

    return (
        <Container>
            <Row className={classes.electoralvotesRow}>
                <Col xs={6} md={3} className={classes.leftThirdParty}>
                    <ElectoralVoteSection partyName="Left Third Party" totalEVs={props.voteTotals.LeftThirdParty} originalEVs={originalElectoralVotes.LeftThirdParty} />
                </Col>
                <Col xs={6} md={2} className={classes.democrats}>
                    <ElectoralVoteSection partyName="Democrats" totalEVs={props.voteTotals.Democrats} originalEVs={originalElectoralVotes.Democrats} />
                </Col>
                <Col xs={12} md={2} className={classes.ties}>
                    <ElectoralVoteSection partyName="Ties" totalEVs={props.voteTotals.Ties} originalEVs={originalElectoralVotes.Ties} />
                </Col>
                <Col xs={6} md={2} className={classes.republicans}>
                    <ElectoralVoteSection partyName="Republicans" totalEVs={props.voteTotals.Republicans} originalEVs={originalElectoralVotes.Republicans} />
                </Col>
                <Col xs={6} md={3} className={classes.rightThirdParty}>
                    <ElectoralVoteSection partyName="Right Third Party" totalEVs={props.voteTotals.RightThirdParty} originalEVs={originalElectoralVotes.RightThirdParty} />
                </Col>
            </Row>
        </Container>
    )
}

export default ElectoralVotes;