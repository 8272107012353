export const WinningParty  = (DemocratVote, LeftThirdVote, RepublicanVote, RightThirdVote) => {
    if ((DemocratVote > LeftThirdVote) && (DemocratVote > RepublicanVote) && (DemocratVote > RightThirdVote)) {
        return 'Democrats';
    }
    if ((LeftThirdVote > DemocratVote) && (LeftThirdVote > RepublicanVote) && (LeftThirdVote > RightThirdVote)) {
        return 'LeftThirdParty';
    }
    if ((RepublicanVote > LeftThirdVote) && (RepublicanVote > DemocratVote) && (RepublicanVote > RightThirdVote)) {
        return 'Republicans';
    }
    if ((RightThirdVote > LeftThirdVote) && (RightThirdVote > DemocratVote) && (RightThirdVote > RepublicanVote)) {
        return 'RightThirdParty';
    }
    return "Tie";
}

export const WinningPct  = (TotalVotes, WinnerVotes, PartyAVote, PartyBVote, PartyCVote) => {
    const WinnerVotePct = WinnerVotes/TotalVotes*100;
    let RunnerUpPct = 0;
    
    if (PartyAVote > PartyBVote) {
        if (PartyAVote > PartyCVote) {
            RunnerUpPct = PartyAVote/TotalVotes*100;
        } else {
            RunnerUpPct = PartyCVote/TotalVotes*100;
        }
    } else {
        if (PartyBVote > PartyCVote) {
            RunnerUpPct = PartyBVote/TotalVotes*100;
        } else {
            RunnerUpPct = PartyCVote/TotalVotes*100;
        }
    }

    return (WinnerVotePct - RunnerUpPct);
}

export const calculateElectoralVotes = (MapData, Totals) => {
    const electoralVoteObj = {
        Democrats: 0,
        LeftThirdParty: 0,
        Republicans: 0,
        RightThirdParty: 0,
        Ties: 0
    }
    MapData.map((StateObj) => {
        let DemocratVote = StateObj.democrats * (100 - Totals.dem.thirdParty - Totals.dem.stayHome - Totals.dem.voteOpposite)/100;
        const LeftThirdVote = StateObj.democrats * (Totals.dem.thirdParty)/100;
        const DemocratVoteOpposite = StateObj.democrats * (Totals.dem.voteOpposite)/100;

        let RepublicanVote = StateObj.republicans * (100 - Totals.rep.thirdParty - Totals.rep.stayHome - Totals.rep.voteOpposite)/100;
        const RightThirdVote = StateObj.republicans * (Totals.rep.thirdParty)/100;
        const RepublicanVoteOpposite = StateObj.republicans * (Totals.rep.voteOpposite)/100;
        
        DemocratVote = DemocratVote + RepublicanVoteOpposite;
        RepublicanVote = RepublicanVote + DemocratVoteOpposite;

        switch(WinningParty(DemocratVote, LeftThirdVote, RepublicanVote, RightThirdVote)) {
            case "Democrats":
                electoralVoteObj.Democrats += StateObj.electoralVotes;
                break;
            case "LeftThirdParty":
                electoralVoteObj.LeftThirdParty += StateObj.electoralVotes;
                break;
            case "Republicans":
                electoralVoteObj.Republicans += StateObj.electoralVotes;
                break;
            case "RightThirdParty":
                electoralVoteObj.RightThirdParty += StateObj.electoralVotes;
                break;
            default:
                electoralVoteObj.Ties += StateObj.electoralVotes;
        }

        return '';
    });

    return electoralVoteObj;
};

export const getVoteColor = (Democrat, Republican, Totals) => {
    const TotalVotes = Democrat + Republican;

    let DemocratVote = Democrat * (100 - Totals.dem.thirdParty - Totals.dem.stayHome - Totals.dem.voteOpposite)/100;
    const LeftThirdVote = Democrat * (Totals.dem.thirdParty)/100;
    const DemocratVoteOpposite = Democrat * (Totals.dem.voteOpposite)/100;

    let RepublicanVote = Republican * (100 - Totals.rep.thirdParty - Totals.rep.stayHome - Totals.rep.voteOpposite)/100;
    const RightThirdVote = Republican * (Totals.rep.thirdParty)/100;
    const RepublicanVoteOpposite = Republican * (Totals.rep.voteOpposite)/100;

    DemocratVote = DemocratVote + RepublicanVoteOpposite;
    RepublicanVote = RepublicanVote + DemocratVoteOpposite;

    let WinnerPct = 0;
    
    switch(WinningParty(DemocratVote, LeftThirdVote, RepublicanVote, RightThirdVote)) {
        case "Democrats":
            WinnerPct = WinningPct(TotalVotes, DemocratVote, LeftThirdVote, RepublicanVote, RightThirdVote);
            if (WinnerPct >= 10) {
                return "darkblue";
            } else {
                if (WinnerPct >= 5) {
                    return "blue";
                } else {
                    return "lightblue";
                }
            }
        case "LeftThirdParty":
            WinnerPct = WinningPct(TotalVotes, LeftThirdVote, RepublicanVote, DemocratVote, RightThirdVote);
            if (WinnerPct >= 10) {
                return "darkgreen";
            } else {
                if (WinnerPct >= 5) {
                    return "green";
                } else {
                    return "palegreen";
                }
            }
        case "Republicans":
            WinnerPct = WinningPct(TotalVotes, RepublicanVote, DemocratVote, LeftThirdVote, RightThirdVote);
            if (WinnerPct >= 10) {
                return "darkred";
            } else {
                if (WinnerPct >= 5) {
                    return "red";
                } else {
                    return "lightpink";
                }
            }
        case "RightThirdParty":
            WinnerPct = WinningPct(TotalVotes, RightThirdVote, RepublicanVote, DemocratVote, LeftThirdVote);
            if (WinnerPct >= 10) {
                return "darkorange";
            } else {
                if (WinnerPct >= 5) {
                    return "orange";
                } else {
                    return "lightsalmon";
                }
            }
        default:
            return "grey";
    }
}

export const Initial_Map_State = () => {
    return (
        [
            {
                name: "AL",
                electoralVotes: 9,
                democrats: 849624,
                republicans: 1441170
            },
            {
                name: "AK",
                electoralVotes: 3,
                democrats: 153778,
                republicans: 189951
            },
            {
                name: "AZ",
                electoralVotes: 11,
                democrats: 1672143,
                republicans: 1661686
            },
            {
                name: "AR",
                electoralVotes: 6,
                democrats: 423932,
                republicans: 760647
            },
            {
                name: "CA",
                electoralVotes: 54,
                democrats: 11110639,
                republicans: 6006518
            },
            {
                name: "CO",
                electoralVotes: 10,
                democrats: 1804352,
                republicans: 1364607
            },
            {
                name: "CT",
                electoralVotes: 7,
                democrats: 1080831,
                republicans: 714717
            },
            {
                name: "DE",
                electoralVotes: 3,
                democrats: 296268,
                republicans: 200603
            },
            {
                name: "DC",
                electoralVotes: 3,
                democrats: 317323,
                republicans: 18586
            },
            {
                name: "FL",
                electoralVotes: 30,
                democrats: 5297045,
                republicans: 5668731
            },
            {
                name: "GA",
                electoralVotes: 16,
                democrats: 2473633,
                republicans: 2461854
            },
            {
                name: "HI",
                electoralVotes: 4,
                democrats: 366130,
                republicans: 196864
            },
            {
                name: "ID",
                electoralVotes: 4,
                democrats: 287021,
                republicans: 554119
            },
            {
                name: "IL",
                electoralVotes: 19,
                democrats: 3471915,
                republicans: 2446891
            },
            {
                name: "IN",
                electoralVotes: 11,
                democrats: 1242498,
                republicans: 1729857
            },
            {
                name: "IA",
                electoralVotes: 6,
                democrats: 759061,
                republicans: 897672
            },
            {
                name: "KS",
                electoralVotes: 6,
                democrats: 570323,
                republicans: 771406
            },
            {
                name: "KY",
                electoralVotes: 8,
                democrats: 772474,
                republicans: 1326646
            },
            {
                name: "LA",
                electoralVotes: 8,
                democrats: 856034,
                republicans: 1255776
            },
            {
                name: "ME",
                electoralVotes: 2,
                democrats: 435072,
                republicans: 360737
            },
            {
                name: "ME-1",
                electoralVotes: 1,
                democrats: 266376,
                republicans: 164045
            },
            {
                name: "ME-2",
                electoralVotes: 1,
                democrats: 168696,
                republicans: 196692
            },
            {
                name: "MD",
                electoralVotes: 10,
                democrats: 1985023,
                republicans: 976414
            },
            {
                name: "MA",
                electoralVotes: 11,
                democrats: 2382202,
                republicans: 1167202
            },
            {
                name: "MI",
                electoralVotes: 15,
                democrats: 2804040,
                republicans: 2649852
            },
            {
                name: "MN",
                electoralVotes: 10,
                democrats: 1717077,
                republicans: 1484065
            },
            {
                name: "MS",
                electoralVotes: 6,
                democrats: 539398,
                republicans: 756764
            },
            {
                name: "MO",
                electoralVotes: 10,
                democrats: 1253014,
                republicans: 1718736
            },
            {
                name: "MT",
                electoralVotes: 4,
                democrats: 244786,
                republicans: 343602
            },
            {
                name: "NE",
                electoralVotes: 2,
                democrats: 374583,
                republicans: 556846
            },
            {
                name: "NE-1",
                electoralVotes: 1,
                democrats: 132261,
                republicans: 180290
            },
            {
                name: "NE-2",
                electoralVotes: 1,
                democrats: 176468,
                republicans: 154377
            },
            {
                name: "NE-3",
                electoralVotes: 1,
                democrats: 65854,
                republicans: 222179
            },
            {
                name: "NV",
                electoralVotes: 6,
                democrats: 703486,
                republicans: 669890
            },
            {
                name: "NH",
                electoralVotes: 4,
                democrats: 424937,
                republicans: 365660
            },
            {
                name: "NJ",
                electoralVotes: 14,
                democrats: 2608400,
                republicans: 1883313
            },
            {
                name: "NM",
                electoralVotes: 5,
                democrats: 501614,
                republicans: 401894
            },
            {
                name: "NY",
                electoralVotes: 28,
                democrats: 5244886,
                republicans: 3251997
            },
            {
                name: "NC",
                electoralVotes: 16,
                democrats: 2684292,
                republicans: 2758775
            },
            {
                name: "ND",
                electoralVotes: 3,
                democrats: 115042,
                republicans: 235751
            },
            {
                name: "OH",
                electoralVotes: 17,
                democrats: 2679165,
                republicans: 3154834
            },
            {
                name: "OK",
                electoralVotes: 7,
                democrats: 503890,
                republicans: 1020280
            },
            {
                name: "OR",
                electoralVotes: 8,
                democrats: 1340383,
                republicans: 958448
            },
            {
                name: "PA",
                electoralVotes: 19,
                democrats: 3458229,
                republicans: 3377674
            },
            {
                name: "RI",
                electoralVotes: 4,
                democrats: 307486,
                republicans: 199922
            },
            {
                name: "SC",
                electoralVotes: 9,
                democrats: 1091541,
                republicans: 1385103
            },
            {
                name: "SD",
                electoralVotes: 3,
                democrats: 150471,
                republicans: 261043
            },
            {
                name: "TN",
                electoralVotes: 11,
                democrats: 1143711,
                republicans: 1852475
            },
            {
                name: "TX",
                electoralVotes: 40,
                democrats: 5259126,
                republicans: 5890347
            },
            {
                name: "UT",
                electoralVotes: 6,
                democrats: 560282,
                republicans: 865140
            },
            {
                name: "VT",
                electoralVotes: 3,
                democrats: 242820,
                republicans: 112704
            },
            {
                name: "VA",
                electoralVotes: 13,
                democrats: 2413568,
                republicans: 1962430
            },
            {
                name: "WA",
                electoralVotes: 12,
                democrats: 2369612,
                republicans: 1584651
            },
            {
                name: "WV",
                electoralVotes: 4,
                democrats: 235984,
                republicans: 545382
            },
            {
                name: "WI",
                electoralVotes: 10,
                democrats: 1630866,
                republicans: 1610184
            },
            {
                name: "WY",
                electoralVotes: 3,
                democrats: 73491,
                republicans: 193559
            }
        ]
    );
}


export const InitializePathValue = (pathValue) => {
    let returnValue = pathValue || 0;
    if (isNaN(returnValue) || returnValue < 0) {
        returnValue = 0;
    }
    if (returnValue > 100) {
        returnValue = 100;
    }
    returnValue = Math.floor(returnValue); // Prevent float
    
    return returnValue;
}